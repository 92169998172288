/*CORE*/
import React, {useState} from 'react'
import {Button, Form, message, Switch, Typography} from 'antd'
import {connect, ConnectedProps} from "react-redux";
import {useLocation} from "react-router-dom";
/*COMPONENTS*/
import AppIdHeader from '../../../../MiniApps/components/AppIdHeader/AppIdHeader'
import InfoTooltip from '../../../../../common/InfoTooltip'
import Badge from '../../../../components/Badge/Badge'
/*CONSTANTS*/
import { DOCS_URL } from 'utils/constants'
/*TYPES*/
import { IPartnershipDetails } from 'types/Partnerships'
/*STYLES*/
import styles from './PartnerIntegration.module.scss'
/*SELECTORS*/
import {updatePartnership} from "../../../../../../store/partnerships/actions";

const { Title } = Typography

interface Props extends ConnectedProps<typeof connector>{
  partnership: IPartnershipDetails
}

const REQUIRED_FIELDS_EXAMPLES = {
  name: 'Bob',
  email: 'user@example.com',
  phone: '+65 1111 1111'
}

const PartnerIntegration = ({ partnership, updatePartnership }: Props) => {
  const [ form ] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation().pathname.split('/');
  const partnershipId = +location[location.length - 2];

  const onSubmit = (data: any) => {
    setIsLoading(true)
    updatePartnership(partnershipId, data)
      .then(() => {
        setIsLoading(false);
        message.success('Successfully saved');
      })
  }

  return (
    <Form
      form={form}
      layout='horizontal'
      onFinish={onSubmit}
      initialValues={{
        single_sign_on_enabled: partnership.single_sign_on_enabled,
        payments_enabled: partnership.payments_enabled,
      }}
    >
      <div className={styles['partner-integration']}>
        <div className={styles['partner-integration__section']}>
          <Title level={3}>App ID</Title>
          <div className={styles['partner-integration__header']}>
            <AppIdHeader
              appId={partnership.miniapp.app_id}
              description={`Use the following App ID to launch ${partnership.miniapp.name}:`}
            />
            <div>
              <Button
                type='primary'
                htmlType='submit'
                size='small'
                loading={isLoading}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
        <div className={styles['partner-integration__section']}>
          <Title level={3}>White-labeling</Title>
          <div className={styles['inherit-colors']}>
            <span className={styles['label-tooltip']}>
              Inherit hostapp colors
              <InfoTooltip title='When turned on, host app theming colors will be passed to miniapp instead'/>
            </span>
            <Badge
              status={partnership.hostapp.share_colors ? 'success' : 'warning'}
              label={partnership.hostapp.share_colors ? 'Enabled' : 'Disabled'}
            />
          </div>
        </div>
        <div className={styles['partner-integration__section']}>
          <Title level={3}>Other</Title>
          <Form.Item
            name='single_sign_on_enabled'
            label='Boxo connect enabled'
            valuePropName='checked'
            colon={false}
          >
            <Switch />
          </Form.Item>
          <Form.Item
            name='payments_enabled'
            label='Payments enabled'
            valuePropName='checked'
            colon={false}
          >
            <Switch />
          </Form.Item>
        </div>
        {partnership.miniapp.required_fields.length > 0 && (
          <div className={styles['partner-integration__section']}>
            <Title level={3}>Boxo Connect</Title>
            <div style={{ marginBottom: 30 }}>
              <a href={`${DOCS_URL}/hostapp/boxo-connect.html`} target='_blank' rel='noopener noreferrer'>Boxo Connect
              </a> is a set of functions and classes that allows authorization of the host user in the miniapp.
            </div>
            <div>
            <span className={styles['label-tooltip']}>
              Required fields
              <InfoTooltip
                title='Minimum required user detail fields to create an account within this miniapp'
              />
            </span>
              <div className={styles['required-fields']}>
                {partnership.miniapp.required_fields.map((requiredField, index) => <span key={index}>{requiredField}</span>)}
              </div>
              <pre className={styles['required-fields-snippet']}>
              <code>
                <span className={styles['punctuation']}>{'{'}</span><br/>
                {partnership.miniapp.required_fields.map((field, i) => (
                  <>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className={styles['property']}>{field}</span>
                    <span className={styles['operator']}>:</span>
                    &nbsp;<span className={styles['string']}>"{REQUIRED_FIELDS_EXAMPLES[field as 'name' | 'email' | 'phone']}"</span>
                    {i < partnership.miniapp.required_fields.length-1 && <span className={styles['punctuation']}>,</span>}
                    <br />
                  </>
                ))}
                <span className={styles['punctuation']}>{'}'}</span>
              </code>
            </pre>
            </div>
          </div>
        )}
      </div>
    </Form>
  )
}

/* Redux */
const connector = connect(undefined, { updatePartnership });
export default connector(PartnerIntegration)
