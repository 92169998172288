import { ICurrency } from '../types/Payouts'
import { MiniAppOrientations } from '../types'
import { GuideStep } from '../pages/Dashboard/Home/components/WelcomeGuide/WelcomeGuide'

export const BASE_URL = process.env.REACT_APP_BASE_URL || '' as string
export const DEBUG = process.env.NODE_ENV === 'development'
export const PRODUCTION = process.env.NODE_ENV === 'production'
export const STAGING = BASE_URL.includes('staging')
export const DOCS_URL = process.env.REACT_APP_DOCS_URL || ''
export const COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN
export const BOXO_COOKIE_DOMAIN = process.env.REACT_APP_BOXO_COOKIE_DOMAIN
export const MODE_SWITCHER_URL = process.env.REACT_APP_MODE_SWITCHER_URL

export const API_BASE_URL = BASE_URL.concat('/api/v1')

// Common
export const COUNTRY_FLAGS_URL = BASE_URL.concat('/staticfiles/iso-flags/')

// Accounts
export const API_SIGNUP_URL = API_BASE_URL.concat('/accounts/signup/')
export const API_SIGNUP_ORGANIZATION_URL = API_BASE_URL.concat('/accounts/signup_organization/')
export const API_ACTIVATE_URL = API_BASE_URL.concat('/accounts/activate/')
export const API_RESEND_CONFIRMATION = API_BASE_URL.concat('/accounts/signup/resend_confirmation/')
export const API_LOGIN_URL = API_BASE_URL.concat('/accounts/login/')
export const API_RESET_PASSWORD = API_BASE_URL.concat('/accounts/password_reset/')
export const API_RESET_PASSWORD_CONFIRM = API_BASE_URL.concat('/accounts/password_reset/confirm/')
export const API_CHANGE_USER_PASSWORD = API_BASE_URL.concat('/accounts/password/change/')
export const API_USER_PROFILE = API_BASE_URL.concat('/accounts/profile/me/')

// Two factor auth
export const API_GET_2FA_TOKEN = API_BASE_URL.concat('/accounts/totp/get_token/')
export const API_VERIFY_2FA_CODE = API_BASE_URL.concat('/accounts/totp/verify/')
export const API_DISABLE_2FA = API_BASE_URL.concat('/accounts/totp/disable/')
export const API_NEW_2FA_EMERGENCY_CODE = API_BASE_URL.concat('/accounts/totp/get_new_emergency_code/')

// Miniapps
export const API_APPS = API_BASE_URL.concat('/miniapps/')
export const API_CATEGORIES_URL = API_BASE_URL.concat('/miniapps/categories/')
export const API_INTEGRATIONS = API_BASE_URL.concat('/miniapps/integration/')
export const API_USER_APPS = API_BASE_URL.concat('/miniapps/user/')
export const API_INTEGRATED_HOST_APPS = API_BASE_URL.concat('/miniapps/integration/hostapps/')
export const SCREENSHOTS = API_APPS.concat('screens/')
export const REQUEST_COMMERCIAL_MODEL = API_APPS.concat('request_commercial_model/')
export const SCREENSHOTS_DELETE = SCREENSHOTS.concat('delete/')
export const API_APPS_HELP = API_APPS.concat('user/')

// Analytics
export const API_MINIAPP_METRICS = API_BASE_URL.concat('/analytics/miniapp/')
export const API_HOSTAPP_METRICS = API_BASE_URL.concat('/analytics/hostapp/')
export const API_CONSENT_EXPORT = API_BASE_URL.concat('/accounts/consent/export_user_consents/')

// Team
export const API_TEAM = API_BASE_URL.concat('/team/members/')
export const API_LOAD_ROLES = API_BASE_URL.concat('/team/roles/')
export const API_INVITATIONS = API_BASE_URL.concat('/invitations/')
export const API_ACCEPT_INVITE = API_BASE_URL.concat('/invitations/accept/')
export const API_ACCEPT_ORGANIZATION_INVITE = API_BASE_URL.concat('/invitations/validate_invitation_code/')
export const API_ORGANIZATION = API_BASE_URL.concat('/team/organization/my/')

// Partner
export const API_USER_HOST_APPS = API_BASE_URL.concat('/partner/hostapps/')
export const API_ACTIVE_MINIAPPS = API_BASE_URL.concat('/partner/miniapps/')

// Billing
export const API_BILLING_MINIAPP_SUB_PLANS = API_BASE_URL.concat('/billing/miniapp_plans/')
export const API_BILLING_MINIAPP_INVOICES = API_BASE_URL.concat('/billing/miniapp_invoices/')
export const API_BILLING_SUPERAPP_SUB_PLANS = API_BASE_URL.concat('/billing/hostapp_plans/')
export const API_BILLING_SUPERAPP_INVOICES = API_BASE_URL.concat('/billing/hostapp_invoices/')
export const API_BILLING_INTENT = API_BASE_URL.concat('/billing/cards/get_intent/')
export const API_BILLING_CARDS = API_BASE_URL.concat('/billing/cards/')
export const API_BILLING_APPBOXO_BANK_ACCOUNT = API_BASE_URL.concat('/billing/bank_details/appboxo/')

// Payouts
export const API_BILLING_BANK_ACCOUNTS = API_BASE_URL.concat('/billing/bank_details/')
export const API_BILLING_MINIAPP_PAYOUTS = API_BASE_URL.concat('/billing/miniapp_payouts/')
export const API_BILLING_SUPERAPP_PAYOUTS = API_BASE_URL.concat('/billing/hostapp_payouts/')
export const API_BILLING_CURRENCY = API_BASE_URL.concat('/billing/currencies/')

// Role names

export const ROLE_NAMES = {
  ADMIN: 'admin',
  DEV: 'developer'
} as const

// Global enums

export enum IntegrationStatuses {
  Pending,
  Accepted,
  Rejected,
}

// Regex
export const REGEX_WEB_URL = /^((https?):\/\/)?(\w+.)?[a-z0-9]+\.[a-z]{2,4}(\/[a-zA-Z0-9#]+\/?)*$/
export const REGEX_URL = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi
export const REGEX_PASSWORD = /^(?=.*\d)(?=.*[a-zA-Z]).+$/


export enum Permissions {
  IS_STAFF = 'IS_STAFF',
  CAN_MANAGE = 'CAN_MANAGE',
  CAN_EDIT = 'CAN_EDIT'
}

export const FULL_DATE_FORMAT = 'MMM DD, YYYY'

export const ASC = 'ascending'
export const DESC = 'descending'

export const DEFAULT_CURRENCY: ICurrency = {
  id: 1,
  code: 'USD',
  name: 'United States Dollars',
  symbol: '$',
  created_date: new Date(2020, 8, 2),
  updated_date: new Date(2020, 8, 2),
}

export const ALLOWED_SCREENSHOT_SIZES_TO_UPLOAD = {
  [MiniAppOrientations.Landscape]: {
    WIDTH: 1920,
    HEIGHT: 1080,
  },
  [MiniAppOrientations.Portrait]: {
    WIDTH: 1080,
    HEIGHT: 1920,
  },
}

export const ORIENTATION_SIZES = {
  LARGE: {
    [MiniAppOrientations.Portrait]: {
      width: 360,
      height: 640,
    },
    [MiniAppOrientations.Landscape]: {
      width: 640,
      height: 360,
    }
  },
  SMALL: {
    [MiniAppOrientations.Portrait]: {
      width: 180,
      height: 320,
    },
    [MiniAppOrientations.Landscape]: {
      width: 320,
      height: 180,
    }
  }
}

export enum AppType {
  Miniapp = 'miniapp',
  Hostapp = 'hostapp',
}

export enum AppPlanType {
  Launches = 'launches',
  Transactions = 'transactions',
}

export enum PaymentMethodType {
  WireTransfer = 'wire_transfer',
  CreditCard = 'credit_card',
}

export const SCREEN_LARGE_RESOLUTION = 1400

// Welcome guide
export const GUIDE_STEPS: GuideStep[] = [
  {
    id: 1,
    title: 'Read the documentation for miniapps',
    text: 'Learn how to build miniapps with native components and allow authorization of the host app users with one click.',
    link: {
      label: 'Documentation',
      path: `${DOCS_URL}/miniapp/`,
      isExternal: true
    }
  },
  {
    id: 2,
    title: 'Connect your miniapp to Boxo',
    text: 'Once you’ve installed Boxo JS SDK to your miniapp, you are ready to add it to your dashboard',
    link: {
      label: '+ Add miniapp',
      path: '/miniapps/new',
      isExternal: false
    }
  },
  {
    id: 3,
    title: 'Fill in information about your miniapp to submit to Boxo Showroom',
    text: 'Partners need full information about miniapp in order to integrate with you. This includes description, commercial model, terms, screenshots etc.',
    link: {
      label: 'My miniapps',
      path: '/miniapps',
      isExternal: false
    }
  },
  {
    id: 4,
    title: 'Match your miniapp with relevant host app',
    text: 'Contact your account manager to match you with a relevant host apps.',
    link: {
      label: 'Send email',
      path: 'mailto:tracy@appboxo.com?subject=Ready%20to%20integrate%20with%20Super%20Apps%20through%20Appboxo&body=Hi%20Appboxo%20team%2C%0D%0AMy%20miniapp%20is%20ready%20to%20be%20launched.%20Help%20me%20match%20with%20potential%20super%20apps%20in%20Appboxo.%0D%0A%0D%0AThanks!%0D%0A',
      isExternal: true
    }
  },
  {
    id: 5,
    title: 'Read the documentation for host apps',
    text: 'Install one of our Boxo SDKs to your native app, implement custom events and one-click authorization to make fluid user experience.',
    link: {
      label: 'Documentation',
      path: `${DOCS_URL}/hostapp/`,
      isExternal: true
    }
  },
  {
    id: 6,
    title: 'Connect your host app to Boxo',
    text: "Once you've integrated Boxo SDK to your host app, you are ready to add it to your dashboard",
    link: {
      label: '+ Add host app',
      path: '/host-apps/new',
      isExternal: false
    }
  },
  {
    id: 7,
    title: 'Integrate miniapp from Showroom',
    text: 'Check out deals from miniapps, learn their commercial models and start integrating with them.',
    link: {
      label: 'Showroom',
      path: '/showroom',
      isExternal: false
    }
  }
]

export const MINIAPP_GUIDE_STEP_IDS = [1,2,3,4]
export const SUPERAPP_GUIDE_STEP_IDS = [5,6,7]

export const START_WELCOME_GUIDE_KEY = 'startWelcomeGuide'
export const WELCOME_GUIDE_STEPS_KEY = 'welcomeGuideSteps'
export const COMPLETED_GUIDE_STEPS_KEY = 'resolvedGuideSteps'

export const NESTED_ROUTES_PORTAL_TARGET_ID = 'nested-routes-target'
