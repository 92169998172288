/*LIBS*/
import { Dispatch } from 'redux'
import axios from 'axios'
/*TYPES*/
import {
  ExportOrderEmailPayload,
  LOAD_PARTNERSHIPS,
  UpdateUiAction,
} from "./types";
/*CONSTANTS*/
import { API_INTEGRATIONS, API_MINIAPP_METRICS } from "utils/constants";
import {IPartnershipDetails} from "../../types/Partnerships";

export const LOAD_PARTNERSHIPS_REQUEST = 'LOAD_PARTNERSHIPS_REQUEST'
export const LOAD_PARTNERSHIPS_ERROR = 'LOAD_PARTNERSHIPS_ERROR'

export const UPDATE_UI = 'UPDATE_UI'

/*
 * action creators
 */
export function loadPartnerships() {
  return function (dispatch: Dispatch) {
    dispatch({ type: LOAD_PARTNERSHIPS_REQUEST })
    return axios.get(API_INTEGRATIONS)
      .then(response => {
        return dispatch({
          type: LOAD_PARTNERSHIPS,
          partnerships: response.data,
        })
      })
      .catch(e => {
        return dispatch({
          type: LOAD_PARTNERSHIPS_ERROR,
          error: e,
        })
      })
  }
}

export function getPartnership(partnershipId: string) {
  return function (dispatch: Dispatch) {
    return axios.get(API_INTEGRATIONS + partnershipId + '/')
  }
}

export function updatePartnership(partnershipId: number, data: IPartnershipDetails) {
  return function () {
    return axios.patch(API_INTEGRATIONS + partnershipId + '/', data)
  }
}

export const updateUI = (payload: UpdateUiAction['payload']) => {
  return function(dispatch: Dispatch) {
    dispatch({ type: UPDATE_UI, payload })
  }
}

export const exportOrderEmail = (payload: ExportOrderEmailPayload) => {
  return function () {
    return axios.post(
      `${API_MINIAPP_METRICS}send_transactions_email/`,
      payload
    );
  };
};