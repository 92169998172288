import React from "react"
import SinglePageLayout from "pages/Dashboard/components/SinglePageLayout/SinglePageLayout"
import ResultPane from "pages/Dashboard/components/Result/Result"
import { Button } from "antd"

interface Props {
  handleClose: () => void
}

const SendEmailComplete = ({ handleClose }: Props) => {
  return (
    <SinglePageLayout contentPadding='large' onClose={handleClose} isFullHeight>
      <ResultPane
        title='Check your email'
        text='The exported user consent statuses will be sent to your inbox within a few minutes.'
      >
        <Button
          type='primary'
          size='large'
          block
          style={{ width: "340px" }}
          onClick={handleClose}
        >
          Go Back
        </Button>
      </ResultPane>
    </SinglePageLayout>
  )
}

export default SendEmailComplete
