/*CORE*/
import React, { useEffect, useState } from 'react'
/*LIBS*/
import { Button, Form, Input, Radio, Select, Switch, Tooltip, Typography } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { CustomTagProps } from 'rc-select/lib/interface/generator'
import Modal from 'antd/lib/modal/Modal'
import classnames from 'classnames'
import { pick } from 'lodash'
/*COMPONENTS*/
import ColorPicker from '../ColorPicker/ColorPicker'
import InfoTooltip from '../../../../common/InfoTooltip'
import AppIdHeader from '../AppIdHeader/AppIdHeader'
/*HOOKS*/
import useUserPermission from 'hooks/useUserPermission'
/*UTILS*/
import { hasErrors, normalizeFields, withEllipsis } from 'utils/utils'
import AccessControl from '../../../../common/AccessControl'
import { Permissions, REGEX_URL } from 'utils/constants'
/*TYPES*/
import {
  IFormData,
  IMiniApp,
  MiniAppActionBtnPosition,
  MiniAppActionBtnTheme,
  MiniAppOrientations,
  MiniAppWebviewFrameSize
} from 'types'
/*STYLES*/
import styles from './MiniAppSettings.module.scss'

const { Title, Text } = Typography
const { Option } = Select

const FIELDS_TO_VALIDATE: (keyof IMiniApp)[] =
  [
    'publish',
    'primary_color',
    'secondary_color',
    'tertiary_color',
    'webview_frame_size',
    'inherit_colors',
    'orientation',
    'action_button_theme',
    'action_button_position',
    'status_bar_background',
    'white_listed_urls',
  ]

const REQUIRED_FIELDS = [ 'name', 'email', 'phone' ]

interface Props {
  app: IMiniApp
  isSaving: boolean
  onSubmit: (data: IFormData) => void
  onSubmitWithDeleteScreenshots: (data: IFormData) => void
}

export const WhiteListedSelectLabel = ({ value, onClose, appUrl }: CustomTagProps & { appUrl: string | null }) => {

  const isHasTruncate = withEllipsis(value, 30).length < (value as string).length

  return (
    <Tooltip
      align={{ offset: [ 0, -5 ] }}
      trigger={'hover'}
      placement='bottomLeft'
      overlayClassName={'white-listed-select-tooltip'}
      title={isHasTruncate ? value : ''}
    >
      <span className={classnames(styles['white-listed-select-tag'], 'ant-select-selection-item')}>
        <span className={'ant-select-selection-item-content'}>
          {value}
        </span>
        {
          value !== appUrl &&
          <span className={'ant-select-selection-item-remove'} style={{ userSelect: 'none' }} onClick={onClose}>
          <CloseOutlined />
        </span>
        }
    </span>
    </Tooltip>
  )
}

const MiniAppSettings = ({ app, onSubmit, isSaving, onSubmitWithDeleteScreenshots }: Props) => {
  const [ form ] = Form.useForm()
  const [ submitDisabled, setSubmitDisabled ] = useState(true)
  const [ isColorsInherit, setIsColorsInherit ] = useState(true)
  const [ isOpenOrientationWarning, setIsOpenOrientationWarning ] = useState(false)
  const [isAppboxoConnectEnabled, setIsAppboxoConnectEnabeled] = useState(false);
  const [isPaymentsEnabled, setIsPaymentsEnableld] = useState(false);

  const [ isStatusBarBackgroundDisplayed, setIsStatusBarBackgroundDisplayed ] = useState(
    app.webview_frame_size === MiniAppWebviewFrameSize.SafeArea
  )
  const user = useUserPermission()

  useEffect(() => {
    app.inherit_colors !== isColorsInherit && setIsColorsInherit((app.inherit_colors))
    form.resetFields()

    setIsAppboxoConnectEnabeled(app.single_sign_on_integrated)
    setIsPaymentsEnableld(app.payments_integrated)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ app ])

  const handleSubmit = (values: IFormData) => {
    isSameOrientation(app, values) && sendForm(app, values, false)
  }

  const sendForm = (app: IMiniApp, values: IFormData, deleteScreenshots: boolean) => {
    const modifiedValues = { ...values, white_listed_urls: getWhiteListedURLsByAppURL(values) }
    const modifiedApp = { ...app, ...modifiedValues }
    deleteScreenshots ? onSubmitWithDeleteScreenshots(modifiedApp) : onSubmit(modifiedApp)
    setSubmitDisabled(true)
  }

  const isSameOrientation = (app: IMiniApp, values: IFormData): boolean => {
    const showOrientationModal = !!app.screens.length && values.orientation !== app.orientation
    if (showOrientationModal) {
      setIsOpenOrientationWarning(true)
      return false
    } else {
      return true
    }
  }

  const getWhiteListedURLsByAppURL = (values: IFormData): string[] => {
    const currentAppUrl = values.url && !values.url.startsWith('https://') ? `https://${values.url}` : values.url
    const currentWhiteListedURLs = values.white_listed_urls
    return currentWhiteListedURLs.includes(currentAppUrl) || !currentAppUrl
      ? currentWhiteListedURLs
      : [ ...currentWhiteListedURLs, currentAppUrl ]
  }

  const controlWhiteListedUrlsValue = (formValue: { [Key in keyof IMiniApp]: IFormData }) => {
    const whiteListedUrlsFormItem = formValue.white_listed_urls

    if (whiteListedUrlsFormItem.errors.length > 1) {
      const newValue = [ ...whiteListedUrlsFormItem.value ]
      newValue.pop()
      form.setFieldsValue({
        white_listed_urls: [ ...newValue ]
      })
      form.validateFields()
    }
  }

  const controlInheritColorsValue = (formValue: { [Key in keyof IMiniApp]: IFormData }) => {
    formValue.inherit_colors.value !== isColorsInherit && setIsColorsInherit(formValue.inherit_colors.value)
  }

  const isSameRequiredFields = (formValue: { [Key in keyof IMiniApp]: IFormData }) =>
    formValue.required_fields.length === app.required_fields.length &&
    formValue.required_fields.value.every((value: string) => app.required_fields.includes(value))

  const isSameFieldsValue = (formValue: { [Key in keyof IMiniApp]: IFormData }) =>
    FIELDS_TO_VALIDATE.every((field: keyof IMiniApp) => app[field] === formValue[field]?.value)

  const handleFieldsChange = (changedFields: IFormData[], fields: IFormData[]) => {

    const formValue = normalizeFields(fields)

    setIsAppboxoConnectEnabeled((formValue.single_sign_on_integrated.value))
    setIsPaymentsEnableld((formValue.payments_integrated.value))

    controlWhiteListedUrlsValue(formValue)

    controlInheritColorsValue(formValue)

    setSubmitDisabled((isSameRequiredFields(formValue) && isSameFieldsValue(formValue)) || hasErrors(fields))
  }

  const onCancelChangeOrientation = () => {
    const currentOrientation = form.getFieldValue('orientation')
    const prevOrientation =
      currentOrientation === MiniAppOrientations.Portrait ? MiniAppOrientations.Landscape : MiniAppOrientations.Portrait

    form.setFieldsValue({
      orientation: prevOrientation
    })

    setIsOpenOrientationWarning(false)
  }

  const onChangeOrientation = () => {
    setIsOpenOrientationWarning(false)
    const values = form.getFieldsValue()
    sendForm(app, values, true)
  }

  const initialValues = {
    ...(pick(app, FIELDS_TO_VALIDATE)),
    required_fields: app.required_fields,
    url: app.url && app.url.startsWith('https://') ? app.url.slice(8) : app.url,
    get_token_url: app.get_token_url && app.get_token_url.startsWith('https://') ? app.get_token_url.slice(8) : app.get_token_url,
    whitelisted_ips: app.whitelisted_ips,
    single_sign_on_integrated: app.single_sign_on_integrated,
    get_auth_token_url: app.get_auth_token_url,
    payments_integrated: app.payments_integrated,
    webhook_url: app.webhook_url,
  }

  return (
    <div className={styles['miniapp-settings']}>
      <Form
        form={form}
        layout='horizontal'
        onFinish={handleSubmit}
        onFieldsChange={handleFieldsChange}
        initialValues={initialValues}
      >
        <div className={styles['mini-app-form']}>
          <div>
            <div className={styles['mini-app-form__section']}>
              <AppIdHeader appId={app.app_id} description='App ID is used to launch the miniapp' />
            </div>
            <div className={styles['mini-app-form__section']}>
              <Title level={3}>Settings</Title>
              <Form.Item
                label='Miniapp URL'
                name='url'
                rules={[
                  { message: 'Please enter a valid URL of miniapp', pattern: REGEX_URL }
                ]}
                hasFeedback
              >
                <Input
                  placeholder='URL of miniapp'
                  className='ant-input-lg'
                  addonBefore='https://'
                  readOnly={!user.CAN_EDIT}
                />
              </Form.Item>
              {/* <Form.Item
                name='get_token_url'
                label={
                  <span className={styles['label-tooltip']}>
                    Get token URL
                    <InfoTooltip
                      title='This is URL through which the miniapp returns the user token'
                    />
                  </span>
                }
                rules={[
                  { message: 'Please enter a valid get token URL', pattern: REGEX_URL }
                ]}
                colon={false}
                hasFeedback
              >
                <Input
                  placeholder='Enter token URL'
                  className='ant-input-lg'
                  addonBefore='https://'
                  readOnly={!user.CAN_EDIT}
                />
              </Form.Item> */}
              <Form.Item
                name='required_fields'
                label={
                  <span className={styles['label-tooltip']}>
                    Required fields
                    <InfoTooltip
                      title='Minimum required user detail fields to create an account within this miniapp'
                    />
                  </span>
                }
                colon={false}
                hasFeedback
              >
                <Select
                  size='large'
                  mode='multiple'
                  disabled={!user.CAN_EDIT}
                  placeholder='Required Fields'
                >
                  {REQUIRED_FIELDS.map((requiredField) =>
                    <Option key={requiredField} value={requiredField}>{requiredField}</Option>
                  )}
                </Select>
              </Form.Item>
              <Form.Item
                name='white_listed_urls'
                label={
                  <span className={styles['label-tooltip']}>
                    Whitelisted URLs
                    <InfoTooltip
                      title="White listed urls are used to control the way miniapp opens different urls.
                      If not provided, any url with different domain will be treated as external
                      and be opened by phone's default in-app browser."
                    />
                  </span>
                }
                rules={[ {
                  type: 'array',
                  message: 'Please enter valid URLs',
                  defaultField: { type: 'url', message: 'Please enter valid URLs', },
                } ]}
                colon={false}
                hasFeedback
              >
                <Select
                  mode='tags'
                  disabled={!user.CAN_EDIT}
                  placeholder='White Listed URLs'
                  dropdownStyle={{ display: 'none' }}
                  className={styles['white-listed-urls-select']}
                  tagRender={props => <WhiteListedSelectLabel {...props} appUrl={app.url} />}
                />
              </Form.Item>
              <Form.Item
                name='whitelisted_ips'
                label={
                  <span className={styles['label-tooltip']}>
                    Whitelisted IPs
                  </span>
                }
                rules={[ {
                  type: 'array',
                } ]}
                colon={false}
                hasFeedback
              >
                <Select
                  mode='tags'
                  disabled={!user.CAN_EDIT}
                  placeholder='White Listed IPs'
                  dropdownStyle={{ display: 'none' }}
                  className={styles['white-listed-urls-select']}
                />
              </Form.Item>
              <Form.Item
                name='publish'
                valuePropName='checked'
                label='Add to Boxo Showroom'
                colon={false}
              >
                <Switch disabled={!user.CAN_EDIT} />
              </Form.Item>
            </div>

            <div className={styles['mini-app-form__section']}>
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <Title level={3}>Boxo connect</Title>
                <Form.Item
                  style={{marginBottom: 11, flexBasis: 400}}
                  name='single_sign_on_integrated'
                  valuePropName='checked'
                  colon={false}
                >
                  <Switch/>
                </Form.Item>
              </div>

              {isAppboxoConnectEnabled && (
                <div>
                  <Form.Item label='Get auth token URL' name="get_auth_token_url" colon={false}>
                    <Input
                      placeholder={'Get auth token URL'}
                    />
                  </Form.Item>
                </div>
                )}
            </div>

            <div className={styles['mini-app-form__section']}>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <Title level={3}>Payments</Title>
                <Form.Item
                  style={{marginBottom: 11, marginLeft: 175}}
                  name='payments_integrated'
                  valuePropName='checked'
                  colon={false}
                >
                  <Switch/>
                </Form.Item>
              </div>

              {isPaymentsEnabled && (
                <div>
                  <Form.Item label='Webhook URL' name="webhook_url" colon={false}>
                    <Input
                      placeholder={'Webhook URL'}
                    />
                  </Form.Item>
                </div>
              )}
            </div>

            <div className={styles['mini-app-form__section']}>
              <Title level={3}>Properties</Title>
              <Form.Item
                name='orientation'
                label='Orientation'
                colon={false}
              >
                <Radio.Group disabled={!user.CAN_EDIT}>
                  <Radio value={MiniAppOrientations.Auto}>Auto</Radio>
                  <Radio value={MiniAppOrientations.Portrait}>Portrait</Radio>
                  <Radio value={MiniAppOrientations.Landscape}>Landscape</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name='action_button_theme'
                label={
                  <span className={styles['label-tooltip']}>
                    Action button theme
                    <InfoTooltip
                      title='Choose the theme of default buttons provided by our SDK.
                      Action button allow user to close and manage miniapp.'
                    />
                  </span>
                }
                colon={false}
              >
                <Radio.Group disabled={!user.CAN_EDIT}>
                  <Radio value={MiniAppActionBtnTheme.Light}>Light</Radio>
                  <Radio value={MiniAppActionBtnTheme.Dark}>Dark</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name='action_button_position'
                label={
                  <span className={styles['label-tooltip']}>
                    Action button position
                    <InfoTooltip
                      title='Choose the position of default buttons provided by our SDK.
                      Action button allow user to close and manage miniapp.'
                    />
                  </span>
                }
                colon={false}
              >
                <Radio.Group disabled={!user.CAN_EDIT}>
                  <Radio value={MiniAppActionBtnPosition.Fixed}>Fixed</Radio>
                  <Radio value={MiniAppActionBtnPosition.Floating}>Floating</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name='webview_frame_size'
                label={
                  <span className={styles['label-tooltip']}>
                    Webview size
                    <InfoTooltip
                      title="Define miniapp's webview size by either defining full screen which includes status bar or
                      safe area that doesn't."
                    />
                  </span>
                }
                colon={false}
              >
                <Radio.Group disabled={!user.CAN_EDIT} onChange={(val) => {
                  setIsStatusBarBackgroundDisplayed(val.target.value === MiniAppWebviewFrameSize.SafeArea)
                }}>
                  <Radio value={MiniAppWebviewFrameSize.SafeArea}>Safe area</Radio>
                  <Radio value={MiniAppWebviewFrameSize.Fullscreen}>Fullscreen</Radio>
                </Radio.Group>
              </Form.Item>
              {isStatusBarBackgroundDisplayed && (
                <div className={styles['color-input-wrapper']}>
                  <Form.Item
                    name='status_bar_background'
                  >
                    <ColorPicker
                      label='Status bar background'
                      readOnly={!user.CAN_EDIT}
                    />
                  </Form.Item>
                </div>
              )}
            </div>
            <div className={styles['mini-app-form__section']}>
              <Title level={3}>White-labeling</Title>
              <Form.Item
                name='inherit_colors'
                valuePropName='checked'
                label={
                  <span className={styles['label-tooltip']}>
                    Inherit host app colors
                    <InfoTooltip
                      title='When turned on, host app theming colors will be passed to miniapp instead'
                    />
                  </span>
                }
                colon={false}
              >
                <Switch disabled={!user.CAN_EDIT} />
              </Form.Item>
              {
                isColorsInherit &&
                <div className={styles['color-input-wrapper']}>
                  <div className={styles['mini-app-form__description-text']}>
                    Please provide default colors, in case host app doesn't share its theming colors.
                  </div>
                  <Form.Item
                    name='primary_color'
                  >
                    <ColorPicker
                      label='Primary'
                      readOnly={!user.CAN_EDIT}
                    />
                  </Form.Item>
                  <Form.Item
                    name='secondary_color'
                  >
                    <ColorPicker
                      label='Secondary'
                      readOnly={!user.CAN_EDIT}
                    />
                  </Form.Item>
                  <Form.Item
                    name='tertiary_color'
                  >
                    <ColorPicker
                      label='Tertiary'
                      readOnly={!user.CAN_EDIT}
                    />
                  </Form.Item>
                </div>
              }
            </div>
          </div>
          <div className={styles['mini-app-form__save-btn-wrapper']}>
            <AccessControl permission={Permissions.CAN_EDIT}>
              <Button
                size='small'
                type='primary'
                htmlType='submit'
                loading={isSaving}
                disabled={submitDisabled}
              >
                Save
              </Button>
            </AccessControl>
          </div>
        </div>
      </Form>

      <Modal
        centered
        width={500}
        footer={null}
        closable={false}
        bodyStyle={{ padding: '40px' }}
        visible={isOpenOrientationWarning}
        onCancel={onCancelChangeOrientation}
        className={styles['orientation-warning-modal']}
      >
        <Title level={1}>Warning</Title>
        <p className={styles['orientation-warning-modal__content']}>
          You will need to upload new screenshots with
          {app.orientation === MiniAppOrientations.Portrait ? ' landscape ' : ' portrait '}
          orientation. Old screenshots will be deleted.
        </p>
        <div className={styles['orientation-warning-modal__buttons-wrapper']}>
          <Button
            type='primary'
            onClick={onChangeOrientation}
          >
            Continue
          </Button>
          <Button className='btn-outline' onClick={onCancelChangeOrientation}>Cancel</Button>
        </div>
      </Modal>
      <AccessControl permission={Permissions.CAN_EDIT}>
        <div className={styles['mini-app-form__section']}>
          <Title level={3}>Delete miniapp</Title>
          <Text>To delete miniapp, please send an email to <a
            href={`mailto:support@appboxo.com?subject=Delete miniapp request!&body=Miniapp ID: ${app.app_id}`}>support@appboxo.com</a> from
            your business email.</Text>
        </div>
      </AccessControl>
    </div>
  )
}

export default MiniAppSettings
