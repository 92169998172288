/*CORE*/
import React, { useEffect, useState } from 'react'
/*LIBS*/
import { Typography, Form, Input, Button, Select, Upload, Switch } from 'antd'
import { CloseOutlined } from '@ant-design/icons/lib'
import { PlusOutlined } from '@ant-design/icons'
import { xor } from 'lodash'
/*COMPONENTS*/
import CountriesModal from '../../../components/CountriesModal/CountriesModal'
import ScreenshotsUploader from './ScreenshotsUploader/ScreenshotsUploader'
import LogoCropper from '../../../components/LogoCropper/LogoCropper'
import AccessControl from '../../../../common/AccessControl'
import InfoTooltip from '../../../../common/InfoTooltip'
import useUserPermission from 'hooks/useUserPermission'
/*UTILS*/
import { beforeUpload, handleChange, hasErrors, normalizeFields } from 'utils/utils'
import { Permissions, REGEX_WEB_URL } from 'utils/constants'
/*TYPES*/
import { IMiniApp, ICategory, IFormData, ICountriesByContinent } from 'types'
/*STYLES*/
import styles from './MiniAppForm.module.scss'

const countries = require('@saadixl/countries')

const { Title } = Typography
const { Option } = Select
const { TextArea } = Input

interface Props {
  app: IMiniApp,
  isSaving: boolean,
  categories: ICategory[],
  updateMiniApp: () => void
  onRedirectToSettings: () => void
  onSubmit: (data: IFormData) => void
  countries: ICountriesByContinent[]
  miniAppCountriesNamesByCodes: (countriesCodes: string[]) => string[]
}

const MiniAppForm = ({ categories, app, isSaving, onSubmit, updateMiniApp, onRedirectToSettings, miniAppCountriesNamesByCodes }: Props) => {
  const [form] = Form.useForm()
  const { setFieldsValue, validateFields } = form
  const [imageUrl, setImageUrl] = useState(app.logo)
  const [submitDisabled, setSubmitDisabled] = useState(true)
  const [showImageCropModal, setShowImageCropModal] = useState(false)
  const [checkedCountries, setCheckedCountries] = useState<string[]>([])
  const [isOpenCountriesModal, setIsOpenCountriesModal] = useState(false)
  const user = useUserPermission()

  useEffect(() => {
    if (app?.countries) {
      const miniappCountries = [...app.countries]
      setCheckedCountries(miniappCountries)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app.countries])

  const handleSubmit = (values: IFormData) => {
    setSubmitDisabled(true)
    const commercial_model = values.commercial_model.filter(Boolean)
    if (commercial_model.length === 0) {
      commercial_model.push('')
    }
    setFieldsValue({ commercial_model })
    onSubmit({
      requiredFields: app.required_fields,
      getTokenUrl: app.get_token_url,
      ...app,
      ...values,
      commercial_model,
      countries: checkedCountries,
    })
  }

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className='ant-upload-text'>Upload</div>
    </div>
  )

  const hasChangesOrInvalid = (changedFields: IFormData[], fields: IFormData[]) => {
    const values = normalizeFields(fields)

    const disableButton = (
      values.name.value === app.name &&
      values.website.value === app.website &&
      values.category.value === app.category?.id &&
      values.short_description.value === app.short_description &&
      values.long_description.value === app.long_description &&
      values.commercial_model.value === app.commercial_model &&
      values.summary_of_terms.value === app.summary_of_terms &&
      values.hide_commercial_model.value === app.hide_commercial_model &&
      imageUrl === app.logo &&
      !xor(app.countries, checkedCountries).length
    ) || hasErrors(fields)
    setSubmitDisabled(disableButton)
  }

  const handleImageCrop = (data: IFormData) => {
    setShowImageCropModal(false)
    setImageUrl(data.dataUrl)
    setFieldsValue({
      upload: data.dataBlob
    })
  }

  const handleCancelCrop = () => {
    setImageUrl('')
    setShowImageCropModal(false)

    setFieldsValue({
      upload: null
    })
  }

  const onChangeCountries = (countries: string[]) => {
    setFieldsValue({
      countries:
        countries?.length === 1 ? miniAppCountriesNamesByCodes(countries) :
          countries?.length > 1 ? countries.length + ' countries' : undefined
    })
    setCheckedCountries(countries)
    validateFields([countries])
    setIsOpenCountriesModal(false)
  }


  // Initial upload value
  const fileList = [{
    uid: '-1',
    name: `${app.name}.png`,
    status: 'done',
    url: app.logo,
  }]

  const isEditableCategory = app.category !== null ? !!categories.find(c => c.id === app.category!.id) : true

  return (
    <>
      <div className='mini-app-form-wrapper'>
        <Form
          form={form}
          layout='horizontal'
          onFinish={handleSubmit}
          onFieldsChange={hasChangesOrInvalid}
          initialValues={{
            name: app.name,
            upload: fileList,
            website: app.website || '',
            category: app.category?.id,
            short_description: app.short_description,
            long_description: app.long_description,
            summary_of_terms: app.summary_of_terms || '',
            hide_commercial_model: app.hide_commercial_model || false,
            commercial_model: app.commercial_model?.length ? app.commercial_model : [''],
            countries:
              app.countries?.length > 1 ?
                app.countries.length + ' countries' :
                !!app.countries?.length ? countries(app.countries[0])?.name : undefined
          }}
        >
          <div className={styles['mini-app-form']}>
            <div>
              <div className={styles['mini-app-form__section']}>
                <Title level={3}>Miniapp Information</Title>
                <Form.Item
                  name='name'
                  rules={[{
                    required: true,
                    message: 'Please enter your miniapp name',
                  }]}
                  label='Miniapp name'
                  colon={false}
                >
                  <Input
                    placeholder='Miniapp name'
                    size='large'
                    readOnly={!user.CAN_EDIT}
                  />
                </Form.Item>
                <Form.Item
                  name='category'
                  label='Category'
                  colon={false}
                >
                  <Select
                    placeholder='Category'
                    size='large'
                    disabled={!user.CAN_EDIT || !isEditableCategory}
                  >
                    {
                      isEditableCategory ? categories.map((category: ICategory) => (
                        <Option key={category.id} value={category.id}>{category.name}</Option>
                      )) : (
                        <Option value={app.category?.id!} >{app.category?.name}</Option>
                      )
                    }
                  </Select>
                </Form.Item>
                <Form.Item
                  name='website'
                  label='Company website'
                  rules={[{
                    pattern: REGEX_WEB_URL,
                    message: 'Please enter valid url',
                  }]}
                  colon={false}
                >
                  <Input
                    placeholder='Company website URL'
                    className='ant-input-lg'
                    readOnly={!user.CAN_EDIT}
                  />
                </Form.Item>
                <Form.Item
                  name='countries'
                  colon={false}
                  label={
                    <span className={styles['label-tooltip']}>
                      Availability
                      <InfoTooltip
                        title='Choose the countries your miniapp is available in.'
                      />
                    </span>
                  }
                >
                  <Select
                    size='large'
                    disabled={!user.CAN_EDIT}
                    placeholder='Choose countries'
                    dropdownStyle={{ display: 'none' }}
                    onClick={() => setIsOpenCountriesModal(true)}
                  />
                </Form.Item>
                <Form.Item
                  name='short_description'
                  label='Short description'
                  rules={[
                    { max: 70, message: 'Short description should not exceed 70 characters' }
                  ]}
                  colon={false}
                >
                  <Input
                    placeholder='Shortly describe your app in 5-10 words'
                    className='ant-input-lg'
                    readOnly={!user.CAN_EDIT}
                  />
                </Form.Item>
                <div className={styles['long-textarea']}>
                  <Form.Item
                    name='long_description'
                    label='Long description'
                    colon={false}
                  >
                    <TextArea
                      placeholder='Describe your app in details...'
                      className='ant-input-lg'
                      readOnly={!user.CAN_EDIT}
                      rows={12}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className={styles['mini-app-form__section']}>
                <Title level={3}>Commercial model</Title>
                <Form.Item
                  label='Commercial model'
                  colon={false}
                >
                  <Form.List name='commercial_model'>
                    {(fields, { add, remove }) => (
                      <div className={styles['commercial-model-item']}>
                        {fields.map((field, index) => (
                          <Form.Item {...field}>
                            <Input
                              placeholder='Commercial model details'
                              className='ant-input-lg'
                              readOnly={!user.CAN_EDIT}
                              suffix={
                                index > 0 ? (
                                  <AccessControl permission={Permissions.CAN_EDIT}>
                                    <CloseOutlined onClick={() => {
                                      remove(field.name)
                                    }} />
                                  </AccessControl>
                                ) : null
                              }
                            />
                          </Form.Item>
                        ))}

                        <AccessControl permission={Permissions.CAN_EDIT}>
                          <Button
                            type='link'
                            className='btn-link'
                            style={{ marginLeft: 10 }}
                            onClick={() => add()}
                          >
                            + Add item
                          </Button>
                        </AccessControl>
                      </div>
                    )}
                  </Form.List>
                </Form.Item>
                <Form.Item
                  name='hide_commercial_model'
                  valuePropName='checked'
                  colon={false}
                  label={
                    <span className={styles['hide-commercial-label']}>
                      Hide commercial model
                      <InfoTooltip
                        title='Your commercial model will be hidden in showroom and will be available upon request'
                      />
                    </span>
                  }
                >
                  <Switch disabled={!user.CAN_EDIT} />
                </Form.Item>
                <div className={styles['long-textarea']}>
                  <Form.Item
                    name='summary_of_terms'
                    label='Summary of terms'
                    colon={false}
                  >
                    <TextArea
                      placeholder='Describe your app in details...'
                      className='ant-input-lg'
                      readOnly={!user.CAN_EDIT}
                      rows={12}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className={styles['mini-app-form__save-btn-wrapper']}>
              <AccessControl permission={Permissions.CAN_EDIT}>
                <Button
                  type='primary'
                  htmlType='submit'
                  size='small'
                  disabled={submitDisabled}
                  loading={isSaving}
                >
                  Save
                </Button>
              </AccessControl>
            </div>
          </div>
          <div className={styles['mini-app-form__section']}>
            <Title style={{ fontSize: '22px' }}>Appearance</Title>
            <div className={styles['form-item__title']}>App Icon</div>
            <p className={styles['form-item__description']}>Miniapp icon needs to be in PNG format.</p>
            <Form.Item
              name='upload'
              valuePropName='file'
            >
              <Upload
                disabled={!user.CAN_EDIT}
                name='avatar'
                accept='image/x-png'
                listType='picture-card'
                showUploadList={false}
                beforeUpload={file => beforeUpload(file, (imageUrl: string) => setImageUrl(imageUrl))}
                onChange={file => handleChange(file, (fileInfo: any) => {
                  if (file.file.status !== 'done') {
                    setImageUrl(app.logo)
                  } else if (fileInfo.fileList.length === 1) {
                    setImageUrl(fileInfo.file.name)
                    setShowImageCropModal(true)
                  }
                })}
              >
                {imageUrl ? <img src={imageUrl} alt='avatar' style={{ width: '100%' }} /> : uploadButton}
              </Upload>
            </Form.Item>
          </div>
        </Form>

        <CountriesModal
          onChange={onChangeCountries}
          visible={isOpenCountriesModal}
          checkedCountries={checkedCountries}
          title={'Country or Region Availability'}
          closeModal={() => setIsOpenCountriesModal(false)}
          description={'Choose the countries where your mininapp is available in.'}
        />

        <LogoCropper
          isModalVisible={showImageCropModal}
          imgUrl={imageUrl}
          onImageCrop={handleImageCrop}
          onCancelClick={handleCancelCrop}
        />
      </div>

      <div className={styles['form-item__title']}>Screenshots</div>
      <p className={styles['form-item__description']}>
        You are allowed to add up to 5 screenshots. Screenshots should be in resolution of
        Full HD (1920x1080 or 1080x1920) and have aspect ratio 16:9 or 9:16
      </p>

      <ScreenshotsUploader miniApp={app} updateApp={updateMiniApp} />

      <p style={{ marginTop: '24px' }}>
        To upload landscape screenshots, change the orientation of your app in
        <span onClick={onRedirectToSettings} className={styles['settings-link']}> settings</span>.
      </p>
    </>
  )
}

export default MiniAppForm
